<template>
    <div>
    <div class="button-container">
        <a @click="$router.push('./import')">
            <button class="button butt control field">
                {{ i18n('Import') }}
            </button>
        </a>
    </div>
    <enso-table class="box is-paddingless raises-on-hover"
        id="scores"/>
    </div>
</template>

<script>
import { EnsoTable } from '@enso-ui/bulma';

export default {
    name: 'Index',

    components: { EnsoTable },
    inject: ['i18n', 'http', 'route'],
};
</script>

<style scoped lang="scss">
@import '../../../sass/enso.scss';
.button-container {
    display: flex;
    justify-content: left;
    align-items: center;
    margin: 20px;
}

.button {
    padding: 10px;
    font-size: 18px;
    background-color: $primary;
    color: $secondary;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

@media (max-width: 768px) {
    .button-container {
        margin: 10px;
    }

    .button {
        font-size: 16px;
    }
}
</style>
